
export class Blocs {

    constructor()  {
    }
 
    setup()  {
      console.log("Blocs - Bloc Setup");
    }
 
    show_obj()  {
      console.log("Blocs - blocs");
    }
 
 }