<template>
  <div id="app">

    <Arcadebg/>
    <Game />
    <!-- <GameSelect class="invisible"/> -->


  </div>
</template>

<script>
// import GameSelect from './components/GameSelect.vue'
import Game from './components/Game.vue'
import Arcadebg from './components/Arcadebg.vue'

export default {
  name: 'App',
  components: {
    Game,
    // GameSelect,
    Arcadebg
  }
}
</script>

<style>
</style>
