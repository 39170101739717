
import { Blocs } from "./blocs";
import * as THREE from 'three';
import gsap from 'gsap'

export class Std_blocs extends Blocs {
  constructor(p_scene, p_mtl_loader, p_obj_loader, p_block_depart, p_model_std_blocs_array, p_id, p_nb_color) {

    super();
    this.m_is_virus = false
    this.m_virus = null;
    this.m_scene = p_scene;
    this.m_pos_on_grid = new THREE.Vector2(5,0);
    this.m_pos = new THREE.Vector3(250,0,0);
    this.m_bloc_id = p_id
    this.m_brother_id = null
    this.get_bro_id()
    
    this.m_ambiant_color = new THREE.Vector3;
    this.m_line_size;
    this.m_bloc_size;
    this.m_block_lock = p_block_depart;
    this.m_block_is_virus = p_block_depart;
    this.m_close_true = false;  //Detect si la destruction de l'image est effectuée.
    this.m_bloc_char = "T"; //Temporaire
    // this.speed_rotation = 0
    this.speed_rotation = (Math.random() * (10000 - 1 + 1) + 1) / 100000;
 
    this.tempo_int_char = Math.floor(Math.random() * (p_nb_color - 0 + 1) + 0);
    // this.tempo_int_char = 0;



      //Création du nuage de particules
      const textureLoader = new THREE.TextureLoader()
      const particuleText = textureLoader.load('/textures/particles/2.png')

      /**
       * Nuages de particules
       */
      const particulesGeo =  new THREE.BufferGeometry()
      const particulesMat = new THREE.PointsMaterial({
          size: 5,
          sizeAttenuation: true,
      })
      particulesMat.color = new THREE.Color('orange')
      particulesMat.transparent = true
      particulesMat.alphaMap = particuleText
      particulesMat.opacity = 0; 
      // particulesMat.alphaTest = 0.001 //excellent
      particulesMat.depthWrite = false
      particulesMat.blending = THREE.AdditiveBlending
      particulesMat.vertexColors = true
      this.m_particules = new THREE.Points(particulesGeo,particulesMat)
      const count = 50000;

      const positions = new Float32Array(count * 3)
      const color = new Float32Array(count * 3)

      for(let i = 0; i < count * 3; i += 3)
      {
          let theta = THREE.MathUtils.randFloatSpread(100);
          let phi = THREE.MathUtils.randFloatSpread(100);
          let distance = THREE.MathUtils.randFloatSpread(1);

          positions[i] = distance * Math.sin(theta) * Math.cos(phi);
          positions[i+1] = distance * Math.sin(theta) * Math.sin(phi);
          positions[i+2] = distance * Math.cos(theta);

          color[i] = Math.random()
          color[i+1] = Math.random()
          color[i+1] = Math.random()
      }

      particulesGeo.setAttribute(
          'position',
          new THREE.BufferAttribute(positions,3)
      )

      particulesGeo.setAttribute(
          'color',
          new THREE.BufferAttribute(color,3)
      )
      this.m_scene.add(this.m_particules)


      document.addEventListener('keydown', (key) => {
        if (key.key === 'e')
        {
          gsap.to(this.m_particules.scale, { duration: 0.5, delay: 0, x: 100, y: 100 , z: 100})
          gsap.to(this.m_particules.material, { duration : 0.5, opacity: 0.3, delay: 0})
        }
        if (key.key === 'r')
        {
          gsap.to(this.m_particules.scale, { duration: 0.5, delay: 0, x: 1, y: 1 , z: 1})
          gsap.to(this.m_particules.material, { duration : 0.5, opacity: 1, delay: 0})
        }
      })


    switch (this.tempo_int_char)
    {
      case 0:
        
      this.m_bloc_char = 'R';
      this.m_virus = p_model_std_blocs_array[0].clone();
      this.show_obj(p_scene)
      // this.show_obj(this.m_virus)
        break;
      case 1:
        this.m_bloc_char = 'G';
        this.m_virus = p_model_std_blocs_array[1].clone();
        this.show_obj(p_scene)
        break;
      case 2:
        this.m_bloc_char = 'B';
        this.m_virus = p_model_std_blocs_array[2].clone();
        this.show_obj(p_scene)
        break;
      case 3:
        this.m_bloc_char = 'Y';
        this.m_virus = p_model_std_blocs_array[3].clone();
        this.show_obj(p_scene)
        break;
      case 4:
        this.m_bloc_char = 'V';
        this.m_virus = p_model_std_blocs_array[4].clone();
        this.show_obj(p_scene)
        break;
      case 5:
        this.m_bloc_char = 'P';
        this.m_virus = p_model_std_blocs_array[5].clone();
        this.show_obj(p_scene)
        break;
      
      default: //Faut pas que ca arrive :)
        this.m_bloc_char = '.';
        break;
    }
    this.m_virus.position.x = 50;
    this.m_particules.position.x = 50; //adjust VR
    this.m_virus.position.y = 325;
    this.m_particules.position.y = 325 //adjust VR
    this.m_virus.position.z = -800;
    this.m_particules.position.z = -800;
    
  }
  
  show_obj(p_scene, p_game)  {
      p_scene.add(this.m_virus);
      console.log(p_game)
  }
  rotX(p_deg)
  {
    // console.log(this.m_blocs_array)
    this.m_virus.rotateX(p_deg);
  }
  rotY(p_deg)
  {
    // console.log(this.m_blocs_array)
    this.m_virus.rotateY(p_deg);
  }
  rotZ(p_deg)
  {
    // console.log(this.m_blocs_array)
    this.m_virus.rotateZ(p_deg);
  }
  change_speed_rotation(p_new_speed){
    // this.speed_rotation = p_new_speed;
    this.speed_rotation = p_new_speed;
    
  }
  get_model(){
    return this.m_virus
  }
  get_bro_id()
  {
    if (this.m_bloc_id % 2 === 1)
    {
      this.m_brother_id = this.m_bloc_id + 1
    }
    else
    {
      this.m_brother_id = this.m_bloc_id - 1
    }
  }

  destroy(callback){
    this.m_scene.remove(this.m_virus)
    this.m_particules.material.opacity = 1; 

    gsap.to(this.m_particules.scale, { duration: 0.5, delay: 0, x: 200, y: 200 , z: 200})
    gsap.to(this.m_particules.material, { duration : 0.5, opacity: 0, delay: 0})



    setTimeout(() => {
      this.m_scene.remove(this.m_particules)
    }, 400)

    


    if(callback)
    {
      callback()
    }

  }
  update_position(){
    this.m_particules.position.x = this.m_virus.position.x
    this.m_particules.position.y = this.m_virus.position.y
  }
}