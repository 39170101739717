import { Game } from "../bisk/game";


export class Facto_games {
    m_game = null;

    set_game_type = p_type => {
        switch (p_type) {

            case 'dr_mario':
                this.m_game = Game;
            break;

            default:
            break;

        }
    };


    create_game = (p_type, p_nb_virus) => {
        this.set_game_type(p_type);
        switch (p_type) {
        case 'dr_mario':
            return new this.m_game(p_nb_virus);
        }
        };
    }