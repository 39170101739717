/* eslint-disable no-unused-vars */
import { Facto_blocs } from "../factories/bisk/facto_blocs";
import { Facto_PU } from "../factories/bisk/facto_PU";
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import {MTLLoader} from   'three/examples/jsm/loaders/MTLLoader.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import { VRButton } from 'three/examples/jsm/webxr/VRButton'
import TeleportVR from 'teleportvr'
import GrabVR from 'grabvr'
import { BoxGeometry } from "three";
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { XRHandModelFactory } from 'three/examples/jsm/webxr/XRHandModelFactory.js'; 


export class Game {
    constructor(p_level)  {
      this.actual_level = p_level
      this.init_loading_manager()
      this.init_loaders()
      this.init_levels()
      this.load_txt_fonts()
      this.data_init()
      this.init_cams()
      this.resize_handling()
      this.input_handling()
      this.load_level_music()
      this.load_bg_levels()
      this.load_explosion_sounds()
      this.load_std_bloc_array();
      this.load_bro_link_model()
      this.load_power_up_array();
      this.show_grid()
      this.create_ascii_grid();
      this.load_models_array_then_start_tick();
    }

//------------------------------------------------------------------------------------------------------------------------------------------------
//  ****     **** ******** ********** **      **   *******   *******    ********
// /**/**   **/**/**///// /////**/// /**     /**  **/////** /**////**  **////// 
// /**//** ** /**/**          /**    /**     /** **     //**/**    /**/**       
// /** //***  /**/*******     /**    /**********/**      /**/**    /**/*********
// /**  //*   /**/**////      /**    /**//////**/**      /**/**    /**////////**
// /**   /    /**/**          /**    /**     /**//**     ** /**    **        /**
// /**        /**/********    /**    /**     /** //*******  /*******   ******** 
// //         // ////////     //     //      //   ///////   ///////   ////////  
//------------------------------------------------------------------------------------------------------------------------------------------------

 
    init_loading_manager()
    {
    //------------------------------------------------------------------------------------------------------------------------------------------------
    // **         *******       **     *******   ** ****     **   ********      ****     ****     **     ****     **     **       ********  ******** *******  
    // /**        **/////**     ****   /**////** /**/**/**   /**  **//////**    /**/**   **/**    ****   /**/**   /**    ****     **//////**/**///// /**////** 
    // /**       **     //**   **//**  /**    /**/**/**//**  /** **      //     /**//** ** /**   **//**  /**//**  /**   **//**   **      // /**      /**   /** 
    // /**      /**      /**  **  //** /**    /**/**/** //** /**/**             /** //***  /**  **  //** /** //** /**  **  //** /**         /******* /*******  
    // /**      /**      /** **********/**    /**/**/**  //**/**/**    *****    /**  //*   /** **********/**  //**/** **********/**    *****/**////  /**///**  
    // /**      //**     ** /**//////**/**    ** /**/**   //****//**  ////**    /**   /    /**/**//////**/**   //****/**//////**//**  ////**/**      /**  //** 
    // /******** //*******  /**     /**/*******  /**/**    //*** //********     /**        /**/**     /**/**    //***/**     /** //******** /********/**   //**
    // ////////   ///////   //      // ///////   // //      ///   ////////      //         // //      // //      /// //      //   ////////  //////// //     // 
    //------------------------------------------------------------------------------------------------------------------------------------------------
      const pourcent_loadedtest = document.querySelectorAll('.layer');
      const loadingScreen = document.getElementById( 'loading-screen' );
      this.start_button = document.getElementById('startgame')
      this.loadingManager = new THREE.LoadingManager()
      this.loadingManager.onStart = () =>
      {
        this.start_button.style.display = 'none'
          loadingScreen.classList.remove( 'fade-out' );
          loadingScreen.style.display = 'flex';
          console.log('Onstart')
      }
      this.loadingManager.onLoad = () =>
      {
          this.create_array_of_blocs(() => {
          this.fill_state_table_with_existing_blocs();
          loadingScreen.classList.add( 'fade-out' );
          loadingScreen.style.display = 'none';
        });
          console.log('onLoad')
      }
      this.loadingManager.onProgress = (item, loaded, total) =>
      {
          console.log('onProgress')
          // pourcent_loaded.innerText = Math.round(loaded / total * 100, 2) + '%'

          for(var i=0; i<pourcent_loadedtest.length; i++){
            pourcent_loadedtest[i].setAttribute('data-after', Math.round(loaded / total * 100, 2) + '%');
          }
      }
      this.loadingManager.onError = () =>
      {
          console.log('onError')
      }

    }

    init_loaders()
    {
      //Loaders
      this.m_mtl_loader = new MTLLoader(this.loadingManager);
      this.m_obj_loader = new OBJLoader(this.loadingManager);
      this.m_mtl_loader_PU = new MTLLoader(this.loadingManager);
      this.m_obj_loader_PU = new OBJLoader(this.loadingManager);
      this.textureloader_bro = new THREE.TextureLoader(this.loadingManager)
      this.material_bro = new THREE.MeshPhysicalMaterial(this.loadingManager)
      this.textureLoaderText2 = new THREE.TextureLoader(this.loadingManager)
      this.materialtxt2 = new THREE.MeshPhysicalMaterial(this.loadingManager)
      this.fontLoader = new FontLoader(this.loadingManager)
      this.fontLoader2 = new FontLoader(this.loadingManager)
    }

    init_levels()
    {
    //------------------------------------------------------------------------------------------------------------------------------------------------
    // **       ******** **      ** ******** **       **       ** ****     **   ******** 
    // /**      /**///// /**     /**/**///// /**      /**      /**/**/**   /**  **//////**
    // /**      /**      /**     /**/**      /**      /**      /**/**//**  /** **      // 
    // /**      /******* //**    ** /******* /**      /**      /**/** //** /**/**         
    // /**      /**////   //**  **  /**////  /**      /**      /**/**  //**/**/**    *****
    // /**      /**        //****   /**      /**      /**      /**/**   //****//**  ////**
    // /********/********   //**    /********/********/********/**/**    //*** //******** 
    // //////// ////////     //     //////// //////// //////// // //      ///   ////////  
    //------------------------------------------------------------------------------------------------------------------------------------------------
      this.levels = [{
        level: 0,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 2,
        nbcolor: 1,
        speed: 1
      },
      {
        level: 1,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 4,
        nbcolor: 1,
        speed: 1
      },
      {
        level: 2,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 8,
        nbcolor: 1,
        speed: 1
      },
      {
        level: 3,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 10,
        nbcolor: 1,
        speed: 0.8
      },
      {
        level: 4,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 2,
        nbcolor: 2,
        speed: 1
      },
      {
        level: 5,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 6,
        nbcolor: 2,
        speed: 1
      },
      {
        level: 6,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 12,
        nbcolor: 2,
        speed: 0.8
      },
      {
        level: 7,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 9,
        nbcolor: 3,
        speed: 1
      },
      {
        level: 8,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 10,
        nbcolor: 3,
        speed: 1
      },
      {
        level: 9,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 15,
        nbcolor: 3,
        speed: 0.9
      },
      {
        level: 10,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 5,
        nbcolor: 4,
        speed: 1.2
      },
      {
        level: 11,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 15,
        nbcolor: 4,
        speed: 1.2
      },
      {
        level: 12,
        nb_col: 9,
        nb_lignes: 16,//Pâs pret pour changement de ligne et collones...reste a corrigé des bug.
        nb_virus: 20,
        nbcolor: 4,
        speed: 1.4
      }
      ]

    }

    load_txt_fonts(){

      this.colorText2 = this.textureLoaderText2.load('/text/color2.png')
      this.materialtxt2.map = this.colorText2
      this.materialtxt2.metalness = 0.7539
      this.materialtxt2.roughness = 0.3746


      this.fontLoader2.load("/fonts/helvetiker_regular.typeface.json", (font) => {
        this.font = font;
        this.show_virus_left();
        this.show_level_at()
      });
    }

    data_init()
    {
    //------------------------------------------------------------------------------------------------------------------------------------------------
    // *******       **     **********     **               ** ****     ** ** **********
    // /**////**     ****   /////**///     ****             /**/**/**   /**/**/////**/// 
    // /**    /**   **//**      /**       **//**            /**/**//**  /**/**    /**    
    // /**    /**  **  //**     /**      **  //**    *****  /**/** //** /**/**    /**    
    // /**    /** **********    /**     **********  /////   /**/**  //**/**/**    /**    
    // /**    ** /**//////**    /**    /**//////**          /**/**   //****/**    /**    
    // /*******  /**     /**    /**    /**     /**          /**/**    //***/**    /**    
    // ///////   //      //     //     //      //           // //      /// //     //     
    //------------------------------------------------------------------------------------------------------------------------------------------------

      //Data
      this.m_nb_virus = this.levels[this.actual_level].nb_virus;
      this.m_nb_virus_left = this.m_nb_virus
      this.m_blocs_array = null;
      this.nb_model_to_load = 6;
      this.nb_std_bloc_to_load = 6;
      this.index_model_to_load = 0;
      this.id_std_blocs = 0
      this.id_virus_blocs = 0
      this.nb_PU_to_load = 1;
      this.index_PU_to_load = 0;
      this.control_to_user = false
      this.text_geo_nb_virus_left = 0
      this.text_mesh_nb_virus_left = 0
      this.text_geo_level_at = 0
      this.text_mesh_level_at = 0
      this.nb_lignes = this.levels[this.actual_level].nb_lignes;
      this.nb_col = this.levels[this.actual_level].nb_col;
      this.m_size_grid_slot = 50;
      this.m_all_block_lock = true;
      this.m_all_block_down = false
      this.game_over = false;
      this.actual_orientation = '0'
      this.bro_link = null
      this.bro_link_pos_adjustement_x = -25 - 200 + 200
      this.bro_link_pos_adjustement_y = 0
      this.bro_link_pos_adjustement_z = 0 - 800
      this.vaisseau = null;
      this.block_container = null;

      this.initialRun = true;

      //conroller
      this.controller1 = null
      this.controller2 = null
      this.controllerGrip1 = null
      this.controllerGrip2 = null
      this.triggerIsSelecting = false;
      this.hand1 = null
      this.hand2 = null
      this.gamepads = navigator.getGamepads();
      this.GPmove_up = false
      this.GPmove_down = false
      this.GPmove_down_btn = false
      this.GPmove_right = false
      this.GPmove_left = false

      // this.vrRoom = null;

      //arrays
      this.PU_array = []
      this.m_model_virus_array = [];
      this.m_model_std_bloc_array = [];
      this.m_bro_links_array = []
      this.m_etat_table = []
      this.m_grid = [];

      //INPUT
      this.move_up = false
      this.move_down = false
      this.move_right = false
      this.move_left = false
      this.move_up_ellapse_time = 0
      this.move_down_ellapse_time = 0
      this.move_right_ellapse_time = 0
      this.move_left_ellapse_time = 0

      // Canvas
      this.canvas = document.querySelector('canvas.webgl');

      //Sizes
      this.sizes = {
          width: document.getElementById('gamediv').offsetWidth,
          height: document.getElementById('gamediv').offsetHeight
      };

      // Scene
      this.m_scene = new THREE.Scene();

      //clock
      this.clock = new THREE.Clock();

      //Lights
      this.ambiantLight =  new THREE.AmbientLight(0xffffff, 0.5);
      this.m_scene.add(this.ambiantLight);
      this.pointLight = new THREE.PointLight(0xffffff, 0.5);
      this.pointLight.position.x = 200;
      this.pointLight.position.y = -100;
      this.pointLight.position.z = 400;
      this.m_scene.add(this.pointLight);
      this.pointLight2 = new THREE.PointLight(0xffffff, 0.5);
      this.pointLight2.position.x = 600;
      this.pointLight2.position.y = -100;
      this.pointLight2.position.z = 800;
      this.m_scene.add(this.pointLight2);
      this.pointLight3 = new THREE.PointLight(0xffffff, 0.5);
      this.pointLight3.position.x = -200;
      this.pointLight3.position.y = -100;
      this.pointLight3.position.z = 800;
      this.m_scene.add(this.pointLight3);

    }

    init_cams()
    {



      // Base camera
      this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 1000);
      this.camera.position.set(0, 0, 0);
      this.camera_rigs = new THREE.Group();
      this.camera_rigs.position.set(0,0,100);
      this.camera_rigs.add(this.camera)
      this.m_scene.add(this.camera_rigs);
      this.m_scene.camera = this.camera
      this.init_renderer();


    }

    init_renderer()
    {
      this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true
      });
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      


      //VR SETTING GOTTA MOVE THIS IN A METHOD FIR ITSELF
      this.renderer.xr.enabled = true;
      this.renderer.xr.setReferenceSpaceType("local");
      
      document.body.appendChild(VRButton.createButton(this.renderer));

      this.renderer.xr.addEventListener( "sessionstart", () => {

        this.block_container.position.y -= 75

        this.camera_rigs.translateY(-75);
      });

      this.init_controllers();
  
    }

    init_controllers()
    {

      this.controller1 = this.renderer.xr.getController(0);
      this.controller1.name = 'controller1'
      this.controller1.addEventListener('selectstart', () => {
        console.log("selectStart")
        this.onSelectStart()
      })
      this.controller1.addEventListener('selectend', () => {
        this.onSelectEnd()
      })

      this.controller1.addEventListener( 'connected', ( event ) => {

        this.controller1.add(this.buildController( event.data ));
        this.camera_rigs.add(this.controller1);

      } );

      this.controller2 = this.renderer.xr.getController(1);
      this.controller2.name = 'controller2'
      this.controller2.addEventListener('selectstart', () => {
        this.onSelectStart()
      })
      this.controller2.addEventListener('selectend', () => {
        this.onSelectEnd()
      })

      this.controller2.addEventListener( 'connected', ( event ) => {

        this.controller2.add(this.buildController( event.data ));
        this.camera_rigs.add(this.controller2);

      } );


				// The XRControllerModelFactory will automatically fetch controller models
				// that match what the user is holding as closely as possible. The models
				// should be attached to the object returned from getControllerGrip in
				// order to match the orientation of the held device.

				const controllerModelFactory = new XRControllerModelFactory();

				this.controllerGrip1 = this.renderer.xr.getControllerGrip( 0 );
				this.controllerGrip1.add( controllerModelFactory.createControllerModel( this.controllerGrip1 ) );
				this.camera_rigs.add( this.controllerGrip1 );

				this.controllerGrip2 = this.renderer.xr.getControllerGrip( 1 );
				this.controllerGrip2.add( controllerModelFactory.createControllerModel( this.controllerGrip2 ) );
				this.camera_rigs.add( this.controllerGrip2 );

        
        const handModelFactory = new XRHandModelFactory();

        this.hand1 = this.renderer.xr.getHand( 0 );
        this.hand1.add( handModelFactory.createHandModel( this.hand1, "mesh" ) );
        this.camera_rigs.add( this.hand1 );

        this.hand2 = this.renderer.xr.getHand( 1 );
        this.hand2.add( handModelFactory.createHandModel( this.hand2, "mesh" ) );
        this.camera_rigs.add( this.hand2 );

        //getting the display vr

        this.reportDisplays();

        //Getting the gamepads

        if(navigator.getGamepads) {
          console.log('Gamepad API supported.')

          window.addEventListener('gamepadconnected', (e) => {
            console.log('Gamepad connected ! ' + e.gamepad.index + ' connected.')

            this.gamepads = navigator.getGamepads();
            if(!this.initialRun) {
                // setTimeout(removeGamepads, 1000);
            }
          });
          
          window.addEventListener('gamepaddisconnected', function(e) {
            console.log('Gamepad DISconnected ! ' + e.gamepad.index + ' connected.')
            // setTimeout(removeGamepads, 1000);

          });
        } else {
          console.log('WebVR API and/or Gamepad API not supported by this browser.')
        }

    }

    onSelectStart() {

      this.triggerIsSelecting = true;

    }

    onSelectEnd() {

      this.triggerIsSelecting = false;

    }

    buildController( data ) {

      let geometry, material;

      switch ( data.targetRayMode ) {

        case 'tracked-pointer':
          console.log("TRACKED-POIJNTER !!!");

          geometry = new THREE.BufferGeometry();
          geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( [ 0, 0, 0, 0, 0, - 10 ], 3 ) );
          geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( [ 0.1, 0.7, 0.1, 1, 0.1, 0.1 ], 3 ) );

          material = new THREE.LineBasicMaterial( { vertexColors: true, blending: THREE.AdditiveBlending } );

          return new THREE.Line( geometry, material );

        case 'gaze':
          console.log("GAZE !!!");

          geometry = new THREE.RingGeometry( 25, 25, 32 ).translate( 0, 0, - 50 );
          material = new THREE.MeshBasicMaterial( { opacity: 0.5, transparent: true } );
          return new THREE.Mesh( geometry, material );

      }

    }
    reportDisplays() {
      if(navigator.getVRDisplays)
      {
        console.log("WE GOT A VR ! WebVR API supported")

        navigator.getVRDisplays().then(function(displays) {
          console.log(displays.length + ' displays');
          for(let i = 0; i < displays.length; i++) {
            let cap = displays[i].capabilities;
            // cap is a VRDisplayCapabilities object
            console.log('<br>VR Display ID: ' + displays[i].displayId)
            console.log('<br>VR Display Name: ' + displays[i].displayName)
            console.log('<br>Display can present content: ' + cap.canPresent)
            console.log('<br>Display is separate from the computer\'s main display: ' + cap.hasExternalDisplay)
            console.log('<br>Display can return position info: ' + cap.hasPosition)
            console.log('<br>Display can return orientation info: ' + cap.hasOrientation)
            console.log('<br>Display max layers: ' + cap.maxLayers)
          }
  
        });
      }else{
        console.log("VR NOT SUPPORTED")
      }
      

      setTimeout(this.reportGamepads, 1000);  //à chamger avec async ou callback
      // For VR, controllers will only be active after their corresponding headset is active
    }
    reportGamepads() {
      this.gamepads = navigator.getGamepads();
      
      this.initialRun = false;
    }

    checkGamepadsButtons(){

      console.log("renderer.xr.getSession();")
      // console.log(this.renderer.xr.getSession()?.inputSources[0]?.gamepad?.buttons)

      let i = 0;

      this.gamepads[i] = this.renderer.xr.getSession()?.inputSources[0]?.gamepad
      this.gamepads[1] = this.renderer.xr.getSession()?.inputSources[1]?.gamepad
      if (this.gamepads[i]){
        console.log("GAMEPAD 0 : ")
        console.log(this.gamepads[i]);

        // for(let y = 0; y < this.gamepads[i].buttons.length; ++y) {
        //   console.log("BOUTONS " + y + " : ")
        //   console.log(this.gamepads[i]?.buttons[y].pressed)
        // }
        

          //handle joy up
          if(this.gamepads[1].buttons[4].pressed && this.control_to_user)
          {
            this.GPmove_up = true
          }
          else{
            this.GPmove_up = false
          }

        //handle joy left
        if(this.gamepads[i].axes[2] < -0.5 && this.control_to_user)
        {
          this.GPmove_left = true
        }
        else{
          this.GPmove_left = false
        }

        //handle joy right
        if(this.gamepads[i].axes[2] > 0.5 && this.control_to_user)
        {
          this.GPmove_right = true
        }
        else{
          this.GPmove_right = false
        }

        //handle joy down
        if(this.gamepads[i].axes[3] > 0.8 && this.control_to_user)
        {
          this.GPmove_down = true
        }
        else{
          this.GPmove_down = false
        }

        //   //handle joy down
        if(this.gamepads[i].buttons[4].pressed && this.control_to_user)
        {
          this.GPmove_down_btn = true
        }
        else{
          this.GPmove_down_btn = false
        }

        }

      
      // //POUR GAMEPAD SANS VR
      // this.gamepads = navigator.getGamepads();


      // for(let i = 0; i < this.gamepads?.length; ++i) {

      // if (this.gamepads[i]){

      //   //handle joy up
      //   if(this.gamepads[i].buttons[2].pressed && this.control_to_user)
      //   {
      //     this.GPmove_up = true
      //   }
      //   else{
      //     this.GPmove_up = false
      //   }

      //   //handle joy down
      //   if(this.gamepads[i].axes[5] > 0.5 && this.control_to_user)
      //   {
      //     this.GPmove_down = true
      //   }
      //   else{
      //     this.GPmove_down = false
      //   }

      //   //handle joy left
      //   if(this.gamepads[i].axes[4] < -0.5 && this.control_to_user)
      //   {
      //     this.GPmove_left = true
      //   }
      //   else{
      //     this.GPmove_left = false
      //   }

      //   //handle joy right
      //   if(this.gamepads[i].axes[4] > 0.5 && this.control_to_user)
      //   {
      //     this.GPmove_right = true
      //   }
      //   else{
      //     this.GPmove_right = false
      //   }
      // }


      // }
      
    }
    
    
    resize_handling()
    {
      //Resize window function
      window.addEventListener('resize', () =>
      {
          // Update sizes
          this.sizes.width = document.getElementById('gamediv').offsetWidth;
          this.sizes.height = document.getElementById('gamediv').offsetHeight;

          // Update camera
          this.camera.aspect = this.sizes.width / this.sizes.height;
          this.camera.updateProjectionMatrix();

          // Update renderer
          this.renderer.setSize(this.sizes.width, this.sizes.height);
          this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
      });
    }

    input_handling()
    {
      
      //------------------------------------------------------------------------------------------------------------------------------------------------
      // ** ****     ** *******  **     ** **********         ******    *******   ****     ** ********** *******     *******   **        ********
      // /**/**/**   /**/**////**/**    /**/////**///         **////**  **/////** /**/**   /**/////**/// /**////**   **/////** /**       **////// 
      // /**/**//**  /**/**   /**/**    /**    /**           **    //  **     //**/**//**  /**    /**    /**   /**  **     //**/**      /**       
      // /**/** //** /**/******* /**    /**    /**          /**       /**      /**/** //** /**    /**    /*******  /**      /**/**      /*********
      // /**/**  //**/**/**////  /**    /**    /**          /**       /**      /**/**  //**/**    /**    /**///**  /**      /**/**      ////////**
      // /**/**   //****/**      /**    /**    /**          //**    **//**     ** /**   //****    /**    /**  //** //**     ** /**             /**
      // /**/**    //***/**      //*******     /**           //******  //*******  /**    //***    /**    /**   //** //*******  /******** ******** 
      // // //      /// //        ///////      //             //////    ///////   //      ///     //     //     //   ///////   //////// ////////  
      //------------------------------------------------------------------------------------------------------------------------------------------------
      

      document.addEventListener('keydown', (key) => {
        if (key.key === 'i')
        {
          
          const PU_Factory = new Facto_PU()
          this.vaisseau = PU_Factory.create_PU("vaisseau", this.PU_array[0].clone(), this.bro_link.clone(), this.m_scene, this.m_blocs_array, this.m_etat_table, this.m_blocs_array)
          console.log(this.vaisseau)
        }
        if (key.key === 'h')
        {
          this.game_over = false;
          this.handle_tick();
        }

        if (key.key === 'ArrowUp' && this.control_to_user)
        {
            this.move_up = true
        }
        if (key.key === 'ArrowDown' && this.control_to_user)
        { 
            this.move_down = true
        }
        if (key.key === 'ArrowRight' && this.control_to_user)
        {
            this.move_right = true
        }
        if (key.key === 'ArrowLeft' && this.control_to_user)
        {
            this.move_left = true
        }
        if (key.key === 'q')
        {
            this.camera_rigs.translateX(-50);
        }
        if (key.key === 'e')
        {
            this.camera_rigs.translateX(50);
        }
        if (key.key === '1')
        {
            this.camera_rigs.translateY(-50);
        }
        if (key.key === '2')
        {
            this.camera_rigs.translateY(50);
        }
        if (key.key === 'w')
        {
            this.camera_rigs.translateZ(-50);
        }
        if (key.key === 's')
        {
            this.camera_rigs.translateZ(50);
        }
        if (key.key === 'a')
        {
            this.camera_rigs.rotateY(0.01);
        }
        if (key.key === 'd')
        {
          this.camera_rigs.rotateY(-0.01);
        }
        if (key.key === 'p')
        {
            alert("PAUSE")
        }
      });

      document.addEventListener('keyup', (key) => {
        if (key.key === 'ArrowUp')
        {
          this.move_up = false
          this.move_up_ellapse_last = 0
        }
        if (key.key === 'ArrowDown')
        {
          this.move_down = false
          this.move_down_ellapse_last = 0
        }
        if (key.key === 'ArrowRight')
        {
          this.move_right = false
          this.move_right_ellapse_last = 0
        }
        if (key.key === 'ArrowLeft')
        {
          this.move_left = false
          this.move_left_ellapse_last = 0
        }
      });

    }

    load_level_music(){

      //sounds
      this.audio_listener2 = new THREE.AudioListener();
      this.m_scene.camera.add(this.audio_listener2)
      this.audioLoader = new THREE.AudioLoader();
      this.index_to_fire = 0
      // create a global audio source

      this.musics = []

        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/1.mp3', (buffer) => {
          this.musics[0].setBuffer( buffer );
          this.musics[0].setLoop( true );
          this.musics[0].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/2.mp3', (buffer) => {
          this.musics[1].setBuffer( buffer );
          this.musics[1].setLoop( true );
          this.musics[1].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/3.mp3', (buffer) => {
          this.musics[2].setBuffer( buffer );
          this.musics[2].setLoop( true );
          this.musics[2].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/4.mp3', (buffer) => {
          this.musics[3].setBuffer( buffer );
          this.musics[3].setLoop( true );
          this.musics[3].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/5.mp3', (buffer) => {
          this.musics[4].setBuffer( buffer );
          this.musics[4].setLoop( true );
          this.musics[4].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/6.mp3', (buffer) => {
          this.musics[5].setBuffer( buffer );
          this.musics[5].setLoop( true );
          this.musics[5].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/7.mp3', (buffer) => {
          this.musics[6].setBuffer( buffer );
          this.musics[6].setLoop( true );
          this.musics[6].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/8.mp3', (buffer) => {
          this.musics[7].setBuffer( buffer );
          this.musics[7].setLoop( true );
          this.musics[7].setVolume( 1);
        });
        this.musics.push(new THREE.Audio( this.audio_listener2 ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/9.mp3', (buffer) => {
          this.musics[8].setBuffer( buffer );
          this.musics[8].setLoop( true );
          this.musics[8].setVolume( 1);
        });

    }

    load_bg_levels()
    {
      this.cubemap = []
      this.cubemap.push(new THREE.CubeTextureLoader().load([
        '/textures/environmentMaps/5/px.jpg',
        '/textures/environmentMaps/5/nx.jpg',
        '/textures/environmentMaps/5/py.jpg',
        '/textures/environmentMaps/5/ny.jpg',
        '/textures/environmentMaps/5/pz.jpg',
        '/textures/environmentMaps/5/nz.jpg'
    ]))

    this.cubemap.push(new THREE.CubeTextureLoader().load([
      '/textures/environmentMaps/7/px.jpg',
      '/textures/environmentMaps/7/nx.jpg',
      '/textures/environmentMaps/7/py.jpg',
      '/textures/environmentMaps/7/ny.jpg',
      '/textures/environmentMaps/7/pz.jpg',
      '/textures/environmentMaps/7/nz.jpg'
  ]))
    this.cubemap.push(new THREE.CubeTextureLoader().load([
      '/textures/environmentMaps/0/px.jpg',
      '/textures/environmentMaps/0/nx.jpg',
      '/textures/environmentMaps/0/py.jpg',
      '/textures/environmentMaps/0/ny.jpg',
      '/textures/environmentMaps/0/pz.jpg',
      '/textures/environmentMaps/0/nz.jpg'
  ]))
  this.cubemap.push(new THREE.CubeTextureLoader().load([
    '/textures/environmentMaps/6/px.jpg',
    '/textures/environmentMaps/6/nx.jpg',
    '/textures/environmentMaps/6/py.jpg',
    '/textures/environmentMaps/6/ny.jpg',
    '/textures/environmentMaps/6/pz.jpg',
    '/textures/environmentMaps/6/nz.jpg'
]))
      

      this.m_scene.background = this.cubemap[0]
    }

    load_explosion_sounds(){

      //sounds
      this.audio_listener = new THREE.AudioListener();
      this.m_scene.camera.add(this.audio_listener)
      this.audioLoader = new THREE.AudioLoader();
      this.index_to_fire = 0
      // create a global audio source

      this.sounds = []

        this.sounds.push(new THREE.Audio( this.audio_listener ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/clear1.mp3', (buffer) => {
          this.sounds[0].setBuffer( buffer );
          this.sounds[0].setLoop( false );
          this.sounds[0].setVolume( 1.2);
        });
        this.sounds.push(new THREE.Audio( this.audio_listener ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/clear2.mp3', (buffer) => {
          this.sounds[1].setBuffer( buffer );
          this.sounds[1].setLoop( false );
          this.sounds[1].setVolume( 1.2);
        });
        this.sounds.push(new THREE.Audio( this.audio_listener ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/clear3.mp3', (buffer) => {
          this.sounds[2].setBuffer( buffer );
          this.sounds[2].setLoop( false );
          this.sounds[2].setVolume( 1.2);
        });
        this.sounds.push(new THREE.Audio( this.audio_listener ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/virusxplose.mp3', (buffer) => {
          this.sounds[3].setBuffer( buffer );
          this.sounds[3].setLoop( false );
          this.sounds[3].setVolume( 1.2);
        });

    }

    //Laoding all différent std_bloc in mem
    load_std_bloc_array()
    {
      for(let i = 0; i < this.nb_std_bloc_to_load; i++)
      {
        const textureloader = new THREE.TextureLoader(this.loadingManager);
        const material = new THREE.MeshPhysicalMaterial(this.loadingManager);
        let std_blocColorTexture = null;
        const std_blocAlphaTexture = textureloader.load('/std_bloc/alpha.jpg')
        const std_blocHeightTexture = textureloader.load('/std_bloc/height.jpg')
        const std_blocNormalTexture = textureloader.load('/std_bloc/normal.jpg')
        material.metalness = 0.1
        material.roughness = 0.9
        material.displacementMap = std_blocHeightTexture
        material.displacementScale = 10;
        material.normalMap = std_blocNormalTexture
        material.normalScale.set(10,10)
        material.transparent = true
        material.alphaMap = std_blocAlphaTexture
        std_blocColorTexture = textureloader.load('/std_bloc/' + i + '/color.jpg')
        // let matcapTexture = textureloader.load('/std_bloc/' + i + '/std_mat_caps.png');
        // material.matcap = matcapTexture
        material.map = std_blocColorTexture

        material.envMap = this.cubemap;
        this.m_model_std_bloc_array.push(new THREE.Mesh(
          new THREE.SphereGeometry(22, 24, 24),
          material
        ))
        this.m_model_std_bloc_array[i].geometry.setAttribute('uv2', new THREE.BufferAttribute(this.m_model_std_bloc_array[i].geometry.attributes.uv.array, 2))
  
        std_blocColorTexture.dispose();
        std_blocHeightTexture.dispose();
        std_blocNormalTexture.dispose();
        std_blocColorTexture.dispose();

      }
    }

    //Loading en mémoire du bro-link aussi utilisé pour les tirs du pu vaisseau
    load_bro_link_model()
    {
      //BRO_LINK
      this.doorColorTexture_bro = this.textureloader_bro.load('/textures/bro_link/color.jpg')
      this.doorAlphaTexture_bro = this.textureloader_bro.load('/textures/bro_link/alpha.jpg')
      this.height_bro_link_bro = this.textureloader_bro.load('/textures/bro_link/height.jpg')
      this.material_bro.metalness = 0.7675
      this.material_bro.roughness = 0
      this.material_bro.displacementScale = 20
      this.material_bro.displacementMap = this.height_bro_link_bro
      this.material_bro.map = this.doorColorTexture
      this.material_bro.opacity = 0.3123
      this.material_bro.transparent = true
      this.material_bro.envMap = this.cubemap;
      this.material_bro.needsUpdate = true;
      this.material_bro.side = THREE.DoubleSide

      const sphere = new THREE.Mesh(
          new THREE.SphereGeometry(55, 64, 64),
          
        this.material_bro
      )
        sphere.scale.set(1,0.5,0.5)
        sphere.quaternion.set(0,0,0.707,0.707)
        this.bro_link = sphere

    }

    //loading en mémoire des power-up (model du vaisseau, ...)
    load_power_up_array()
    {
      if (this.index_PU_to_load > this.nb_PU_to_load  - 1){
        this.index_PU_to_load = 0;
        return;
      }
      this.m_mtl_loader_PU.load('/OBJ/PU/v' + (this.index_PU_to_load + 1) + '.mtl', (mtl) => {
        mtl.preload();
        this.m_obj_loader_PU.setMaterials(mtl);

        this.m_obj_loader_PU.load('/OBJ/PU/v' + (this.index_PU_to_load + 1) + '.obj', (root) => {
          this.PU_array[this.index_PU_to_load] = root
          this.index_PU_to_load++;
          this.load_power_up_array();
        });
      });
    }

    //Affiche le cadriller bleu
    show_grid()  {

      const textureloader = new THREE.TextureLoader()
      const doorColorTexture = textureloader.load('/textures/door/color.jpg')
      const doorAlphaTexture = textureloader.load('/textures/door/alpha.jpg')
      const material = new THREE.MeshPhysicalMaterial()
      material.map = doorColorTexture
      material.transparent = true
      material.alphaMap = doorAlphaTexture
      material.side = THREE.DoubleSide

      this.block_container = new THREE.Mesh(
      new THREE.PlaneGeometry(350,700, 1, 1),
      material
      );
      this.block_container.geometry.setAttribute('uv2', new THREE.BufferAttribute(this.block_container.geometry.attributes.uv.array, 2))
      doorColorTexture.dispose();
      doorAlphaTexture.dispose();
      this.block_container.position.z = -600
      this.m_scene.add(this.block_container);

      //FULL GRID
      // let grid_leaf = this.draw_blue_square();
      // let x_pos = 0;
      // let y_pos = 0;
      // for (let i = 0; i < this.levels[this.actual_level].nb_lignes - 1; i++)
      // {
      //     y_pos = -i * this.m_size_grid_slot - 25 ;
      //     for (let y = 0; y < this.levels[this.actual_level].nb_col - 1; y++)
      //     {
      //         x_pos = (y * this.m_size_grid_slot) + 25;
      //         let grid_leaf_clone = grid_leaf.clone();
      //         grid_leaf_clone.position.x = x_pos - 200;
      //         grid_leaf_clone.position.y = y_pos + 325;
      //         grid_leaf_clone.position.z = -800;
      //         this.m_grid.push(grid_leaf_clone);
      //     }
      // }
      // this.m_grid.forEach(element => this.m_scene.add(element));
    }

    create_ascii_grid(){
      return new Promise((resolve) => {
        this.m_etat_table = []
        this.m_etat_table = new Array(this.nb_lignes).fill(0).map(() => new Array(this.nb_col).fill(0));

        //Construction du grid AScii
        for (let i = 0; i < this.nb_lignes; i++)
        {
            for (let y = 0; y < this.nb_col; y++)
            {
                this.m_etat_table[i][y] = '.';
            }
        }
        resolve("create_ascii_grid Loaded")
      })
    }

    //Loading de tous les modèles de virus
    load_models_array_then_start_tick() {
            if (this.index_model_to_load > this.nb_model_to_load - 1){
              this.index_model_to_load = 0;
              this.handle_tick();
              this.musics[this.actual_level].play();
              return;
            }
            this.m_mtl_loader.load('/OBJ/v' + (this.index_model_to_load + 1) + '.mtl', (mtl) => {
              mtl.preload();
              this.m_obj_loader.setMaterials(mtl);

              this.m_obj_loader.load('/OBJ/v' + (this.index_model_to_load + 1) + '.obj', (root) => {
                this.m_model_virus_array[this.index_model_to_load]  = root
                this.index_model_to_load++;
                this.load_models_array_then_start_tick();
              });
            });
    }

    goto_next_level()
    {

      //On recré la grille ASCII
      this.create_ascii_grid();
      //On augmente le nb de virus
      this.musics[this.actual_level].stop()
      this.actual_level ++
      this.musics[this.actual_level].play()

      switch (this.actual_level) {
        case 0:
          this.m_scene.background = this.cubemap[0]
          break;
        case 3:
          this.m_scene.background = this.cubemap[1]
          break;
        case 7:
          this.m_scene.background = this.cubemap[2]
          break;
        case 11:
          this.m_scene.background = this.cubemap[3]
          break;
      }
      
      
      
      this.show_level_at()

      this.m_nb_virus = this.m_nb_virus_left = this.levels[this.actual_level].nb_virus

      //On réajuste les nombre de lignes et collonnes.
      this.nb_lignes = this.levels[this.actual_level].nb_lignes;
      this.nb_col = this.levels[this.actual_level].nb_col;

      //On destroy tous les éléments du array et on le mets à []
      this.m_blocs_array.forEach(element => {
        element.destroy()
      });
      this.m_blocs_array = []
      
      //On supprime tous les bro-link et on mets le array a []
      this.m_bro_links_array.forEach(element => {
        this.m_scene.remove(element)
      });
      this.m_bro_links_array = []

      //on remove de la scene et on supprime l'array de la grid...puis on recrée la grid
      this.m_grid.forEach(element => this.m_scene.remove(element));
      this.m_grid = []
      this.show_grid()
      
      //On recrée le array of bloc et ont remplis la grille ASCII au callback
      this.create_array_of_blocs(() => {
        this.fill_state_table_with_existing_blocs();
      });



      
      this.show_virus_left();
      this.show_state_table()
    }
    
    show_level_at()
    {
      this.m_scene.remove(this.text_mesh_level_at)

      // Text NeedYou
        this.text_geo_level_at = new TextGeometry(" Level : " + this.actual_level.toString(), {
        font: this.font,
        size: 50,
        height: 0.4,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 3,
        bevelSize: 2,
        bevelOffset: 0,
        bevelSegments: 5
      })
      this.text_geo_level_at.center()
      this.text_mesh_level_at = new THREE.Mesh(this.text_geo_level_at, this.materialtxt2)
      this.text_mesh_level_at.position.x = 600 - 200;
      this.text_mesh_level_at.position.y = -50 +325;
      this.text_mesh_level_at.position.z = -800;
      this.text_mesh_level_at.rotateY(-0.3)
      this.m_scene.add(this.text_mesh_level_at)
    

    }
    show_virus_left()
    {
      this.m_scene.remove(this.text_mesh_nb_virus_left)

        // Text NeedYou
          this.text_geo_nb_virus_left = new TextGeometry(this.m_nb_virus_left.toString() + " to KILL", {
          font: this.font,
          size: 50,
          height: 0.4,
          curveSegments: 12,
          bevelEnabled: true,
          bevelThickness: 3,
          bevelSize: 2,
          bevelOffset: 0,
          bevelSegments: 5
        })
        this.text_geo_nb_virus_left.center()
        this.text_mesh_nb_virus_left = new THREE.Mesh(this.text_geo_nb_virus_left, this.materialtxt2)
        this.text_mesh_nb_virus_left.position.x = -400;
        this.text_mesh_nb_virus_left.position.y = 275;
        this.text_mesh_nb_virus_left.position.z = -800;
        this.text_mesh_nb_virus_left.rotateY(0.3)
        this.m_scene.add(this.text_mesh_nb_virus_left)
      
    }

    create_array_of_blocs(callback)  {
      const bloc_Factory = new Facto_blocs()
      this.m_blocs_array = new Array(this.m_nb_virus); 
      for (let i=0; i<this.m_nb_virus; ++i) this.m_blocs_array[i] = null;
      for (let i = 0; i < this.m_blocs_array.length; i++){
        this.id_virus_blocs++
        this.m_blocs_array[i] = bloc_Factory.create_bloc('virus', this.m_scene, this.m_mtl_loader, this.m_obj_loader, true, this.m_model_virus_array, this.nb_lignes, this.nb_col, this.id_virus_blocs, this.levels[this.actual_level].nbcolor)
      } 
    
      if(callback)
      {
        callback();
      }
      
    }

    //Carré bleu pour la grid
    draw_blue_square()
    {
      const textureloader = new THREE.TextureLoader()
      const doorColorTexture = textureloader.load('/textures/door/color.jpg')
      const doorAlphaTexture = textureloader.load('/textures/door/alpha.jpg')
      const material = new THREE.MeshPhysicalMaterial()
      material.map = doorColorTexture
      material.transparent = true
      material.alphaMap = doorAlphaTexture
      material.side = THREE.DoubleSide

      const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(100,100, 1, 1),
      material
      );
      plane.geometry.setAttribute('uv2', new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2))
      doorColorTexture.dispose();
      doorAlphaTexture.dispose();
      return plane;
    }

    show_state_table()
    {
        let ligne = "";
        for (let i = 0; i < this.nb_lignes; i++)
        {
            ligne += i + "|"
            for (let y = 0; y < this.nb_col; y++)
            {
                ligne += this.m_etat_table[i][y];
            }
            console.log(ligne);
            ligne = "";
        }
    }

    fill_state_table_with_existing_blocs(){
      //On vérifie les doublons et on suprime un virus si 2 se trouve au meme endroit.
      for (let y = 0; y < this.m_blocs_array.length; y++){ //Pour tous les blocs, 
        let double_tcheker = 0
        for (let z = 0; z < this.m_blocs_array.length; z++){ //Pour tous les blocs, 
          if (this.m_blocs_array[y].m_pos_on_grid.x === this.m_blocs_array[z].m_pos_on_grid.x && this.m_blocs_array[y].m_pos_on_grid.y === this.m_blocs_array[z].m_pos_on_grid.y)
          {
            double_tcheker ++
            if(double_tcheker > 1)
            {
              
              double_tcheker --
              this.m_blocs_array[z].destroy()
              this.m_blocs_array.splice(z, 1);
              this.m_nb_virus_left --
              
            }
          }
        }
      }


      for (let y = 0; y < this.m_blocs_array.length; y++){ //Pour tous les blocs, 
        this.set_block_from_pos_in_table(this.m_blocs_array[y].m_pos_on_grid.x, this.m_blocs_array[y].m_pos_on_grid.y,this.m_blocs_array[y].m_bloc_char);
      }

    }
    set_block_from_pos_in_table(p_x, p_y, p_char_bloc){
      this.m_etat_table[p_y][p_x] = p_char_bloc;
    }
    verify_if_all_block_lock(){ //deprecated ?
      this.m_all_block_lock = true;
      for (let i = 0; i < this.m_blocs_array.length; i++){
        if (this.m_blocs_array[i].m_block_lock === false){
          this.m_all_block_lock = false;
        }
      } 
    }
    add_bloc()
    {
      this.actual_orientation = "270" //Pour naturalisé(dr mario)...on comment a 270 pour fiter avec le reste du code
      if (this.m_etat_table[0][5] != '.')
      {
        this.throw_game_over();
      }
      else
      {
        const bloc_Factory = new Facto_blocs()

        //Ajout de deux blocs et ensuite on ajoute le bro_link
        this.id_std_blocs ++
        this.m_blocs_array.push(bloc_Factory.create_bloc('std', this.m_scene, this.m_mtl_loader, this.m_obj_loader, false, this.m_model_std_bloc_array,this.nb_lignes, this.nb_col, this.id_std_blocs, this.levels[this.actual_level].nbcolor));
        this.m_blocs_array.at(-1).m_virus.position.x -= 50;
        this.m_blocs_array.at(-1).m_pos_on_grid.x -= 1;
        this.m_etat_table[0][4] = this.m_blocs_array.at(-1).m_bloc_char;

        this.id_std_blocs ++
        this.m_blocs_array.push(bloc_Factory.create_bloc('std', this.m_scene, this.m_mtl_loader, this.m_obj_loader, false, this.m_model_std_bloc_array,this.nb_lignes, this.nb_col, this.id_std_blocs, this.levels[this.actual_level].nbcolor));
        this.m_etat_table[0][5] = this.m_blocs_array.at(-1).m_bloc_char;


        this.m_bro_links_array.push(this.bro_link.clone())
        this.m_bro_links_array.at(-1).rotateZ(Math.PI / 2)
        this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x
        this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y
        this.m_bro_links_array.at(-1).position.z = this.bro_link_pos_adjustement_z
        this.m_bro_links_array.at(-1).first_bro_id = this.id_std_blocs - 1
        this.m_bro_links_array.at(-1).last_bro_id = this.id_std_blocs
        this.m_scene.add(this.m_bro_links_array.at(-1))
        


      }
    }
    throw_game_over()
    {
      this.game_over = true;

      this.musics[this.actual_level].stop();
      this.start_button.style.display = 'flex'
      let container = document.getElementById("container");
      let newDivGameOver = document.createElement("div");
      newDivGameOver.id = "gameover"
      newDivGameOver.className = "gameOver"
      let imgGameOver = document.createElement("img")
      imgGameOver.className = 'gameoverimg'
      imgGameOver.src = "img/gameover.png"
      container.appendChild(newDivGameOver)
      newDivGameOver.appendChild(imgGameOver)
      // this.bg_music.stop();
      console.log("GAME OVER !")

    }
    get_rid_of_that_game()
    {
      // console.log(this.m_blocs_array[0].m_virus)
      // this.m_scene.remove(this.m_blocs_array[0].m_virus)

        this.m_blocs_array.forEach(element => {this.m_scene.remove(element.m_virus)})
        this.m_blocs_array = null;

        this.m_grid.forEach(element => this.m_scene.remove(element));
        this.m_grid = null;



      // TRAVERSING ALL OBJECTS IN THE SCENE
      this.m_scene.traverse((o) => {
        if (o.geometry) {
          o.geometry.dispose()
          // console.log("dispose geometry ", o.geometry)                        
        }
        if (o.material) {
            if (o.material.length) {
                for (let i = 0; i < o.material.length; ++i) {
                    o.material[i].dispose()
                    // console.log("dispose material ", o.material[i])                                
                }
            }
            else {
                o.material.dispose()
                // console.log("dispose material ", o.material)                            
            }
        }
      });
      setTimeout(() => { 
        this.throw_game_over = true;
        this.m_scene = null
        this.camera = null
        this.renderer.dispose()
        this.renderer.renderLists.dispose()
        this.renderer.forceContextLoss() //CEST CA QUI EST LE CLOU DE LA LIBÉRATION DE LA MÉMOIRE !
        this.renderer = null
      }, 2000);


    }
    //Big Method qui effectue le movement si c'est possible pour les deux derniers blocs(nouvellement apparrue) selon la directement demandée
    move_obj(p_x, p_y, p_z)
    {
      let last_block = this.m_blocs_array.at(-1)
      let second_last_block = this.m_blocs_array.at(-2)
      if(p_z)
      {
        console.log("to be implement")
      }

      
      //-----------------------MOUVEMENT DROITE----------------
      if (p_x < 0 ) // Si on bouge vers la droite...
      { 
        let can_move = false
        switch (this.actual_orientation) 
        {
          case '0':
            case '180':
              {
                if(last_block.m_block_lock === false && second_last_block.m_block_lock === false){
                  //Si aucun bloc ne se trouve à droite , on peut bouger.        
                  if (this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] === '.' && 
                  last_block.m_pos_on_grid.x + 1 < this.nb_col  && 
                  last_block.m_block_is_virus === false)
                  { 
                    if (this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] === '.' && 
                    second_last_block.m_pos_on_grid.x + 1 < this.nb_col  && 
                    second_last_block.m_block_is_virus === false)
                      { 
                        can_move = true
                      }
                      else
                      {
                        can_move = false
                        return
                      }
                  }
                  else{
                    can_move = false
                    return
                  }
                }
        
                if (can_move){
                    if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                    {
                      this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                      this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                      this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                      this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                      last_block.m_pos.x += 50;
                      last_block.m_pos_on_grid.x += 1;
                      last_block.m_virus.position.x += 50;
                      second_last_block.m_pos.x += 50;
                      second_last_block.m_pos_on_grid.x += 1;
                      second_last_block.m_virus.position.x += 50;

                      this.m_bro_links_array.at(-1).position.x += 50
                    }
                } 

              }
            break;

          case '90' :
            {
              if(second_last_block.m_block_lock === false && last_block.m_block_lock === false){
                //Si aucun bloc ne se trouve à droite , on peut bouger.        
                if (this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] === '.' && 
                  second_last_block.m_pos_on_grid.x + 1 < this.nb_col  && 
                  second_last_block.m_block_is_virus === false)
                { 
                  can_move = true
                }
                else
                {
                  can_move = false
                  return
                }
              }
        
              if (can_move){
                  if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                  {
                  this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                  this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                  last_block.m_pos.x += 50;
                  last_block.m_pos_on_grid.x += 1;
                  last_block.m_virus.position.x += 50;
                  second_last_block.m_pos.x += 50;
                  second_last_block.m_pos_on_grid.x += 1;
                  second_last_block.m_virus.position.x += 50;
                  this.m_bro_links_array.at(-1).position.x += 50
                  }
              } 
            }
          break;

          case '270' :
            {
              if(last_block.m_block_lock === false && last_block.m_block_lock === false){
                //Si aucun bloc ne se trouve à droite , on peut bouger.        
                if (this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] === '.' && 
                  last_block.m_pos_on_grid.x + 1 < this.nb_col  && 
                  last_block.m_block_is_virus === false)
                { 
                  can_move = true
                }
                else
                {
                  can_move = false
                  return
                }
              }
        
              if (can_move){
                  if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                  {
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                  this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                  this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                  last_block.m_pos.x += 50;
                  last_block.m_pos_on_grid.x += 1;
                  last_block.m_virus.position.x += 50;
                  second_last_block.m_pos.x += 50;
                  second_last_block.m_pos_on_grid.x += 1;
                  second_last_block.m_virus.position.x += 50;
                  this.m_bro_links_array.at(-1).position.x += 50
                  }
              } 
            }
          break;
        }
      }

      //---------END--------------MOUVEMENT DROITE----------------

      
      //-----------------------MOUVEMENT GAUCHE----------------
      if (p_x > 0) // Si on bouge vers la gauche...
      {        
        let can_move = false   
        switch (this.actual_orientation) 
        {
          case '0':
            case '180':
              {
                if(last_block.m_block_lock === false){
                  //Si aucun bloc ne se trouve à gauche, on peut bouger si....  
                  if (this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x - 1] === '.'  &&
                  last_block.m_pos_on_grid.x > 0 &&
                  last_block.m_block_is_virus === false)
                  {  
                    if (this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x - 1] === '.'  &&
                    second_last_block.m_pos_on_grid.x > 0 &&
                    second_last_block.m_block_is_virus === false)
                    {
                      can_move = true
                    }
                    else
                    {
                        can_move = false
                        return
                    }
                  }
                  else
                  {
                      can_move = false
                      return
                  }
                }
                if (can_move){
                    if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                    {
                      this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x - 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                      this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                      this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x - 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                      this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                      last_block.m_pos.x -= 50;
                      last_block.m_pos_on_grid.x -= 1;
                      last_block.m_virus.position.x -= 50;
                      second_last_block.m_pos.x -= 50;
                      second_last_block.m_pos_on_grid.x -= 1;
                      second_last_block.m_virus.position.x -= 50;
                      this.m_bro_links_array.at(-1).position.x -= 50
                    }
                } 
              }
            break
            case '90':
            {
              if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
              {
                //Si aucun bloc ne se trouve à gauche , on peut bouger.        
                if (this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x - 1] === '.' && 
                last_block.m_pos_on_grid.x > 0  && 
                last_block.m_block_is_virus === false)
                { 
                  can_move = true
                }
                else
                {
                  can_move = false
                  return
                }
              }
              if (can_move){
                  if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                  {
                    this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x - 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                    this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                    this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x - 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                    this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                    last_block.m_pos.x -= 50;
                    last_block.m_pos_on_grid.x -= 1;
                    last_block.m_virus.position.x -= 50;
                    second_last_block.m_pos.x -= 50;
                    second_last_block.m_pos_on_grid.x -= 1;
                    second_last_block.m_virus.position.x -= 50;
                    this.m_bro_links_array.at(-1).position.x -= 50
                  }
                }
            }
            break
            case '270':
            {
              if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
              {
                //Si aucun bloc ne se trouve à gauche , on peut bouger.        
                if (this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x - 1] === '.' && 
                second_last_block.m_pos_on_grid.x > 0  && 
                second_last_block.m_block_is_virus === false)
                { 
                  can_move = true
                }
                else
                {
                  can_move = false
                  return
                }
              }
              if (can_move){
                  if(second_last_block.m_block_lock === false && last_block.m_block_lock === false)
                  {
                    this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x - 1] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                    this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                    this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x - 1] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                    this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                    last_block.m_pos.x -= 50;
                    last_block.m_pos_on_grid.x -= 1;
                    last_block.m_virus.position.x -= 50;
                    second_last_block.m_pos.x -= 50;
                    second_last_block.m_pos_on_grid.x -= 1;
                    second_last_block.m_virus.position.x -= 50;
                    this.m_bro_links_array.at(-1).position.x -= 50
                  }
                }
            }
            break

        }
      }
      //-----------END------------MOUVEMENT GAUCHE----------------
      
      //-----------------------MOUVEMENT BAS----------------

  if (p_y < 0) // Si on bouge vers le bas...
  {  
    switch (this.actual_orientation) 
      {
        case '0':
          {
            // console.log("this.actual_orientation === '0'")
            //On traite la collision de l'avant dernier bloc
            if (second_last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] === '.' && second_last_block.m_block_is_virus === false)
            {    
              this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
              second_last_block.m_pos.y -= 50;
              second_last_block.m_pos_on_grid.y += 1;
              second_last_block.m_virus.position.y -= 50;
            }
            else
            {
              second_last_block.m_block_lock = true;
            }
            //On traite la colision du dernier bloc
            if (last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] === '.' && last_block.m_block_is_virus === false)
            {   
              this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
              this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
              last_block.m_pos.y -= 50;
              last_block.m_pos_on_grid.y += 1;
              last_block.m_virus.position.y -= 50;
            }
            else
            {
              last_block.m_block_lock = true;
            }
            // On déplace le bro_link en meme temps
            this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus?.position.x + this.bro_link_pos_adjustement_x + 25
            this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus?.position.y + this.bro_link_pos_adjustement_y - 25
          }
          break
          case '90':
              {
                // console.log("this.actual_orientation === '90 ")
                let last_block_move_down = false
                let second_last_block_move_down = false
                //On traite la collision de l'avant dernier bloc
                if (last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] === '.' && last_block.m_block_is_virus === false && last_block.m_block_lock === false)
                {
                  last_block_move_down = true
                //   //On traite la colision du dernier bloc
                  if (second_last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] === '.' && second_last_block.m_block_is_virus === false && second_last_block.m_block_lock === false)
                  {     
                    second_last_block_move_down = true
                  }  
                  else
                  {
                    last_block.m_block_lock = true;
                    second_last_block.m_block_lock = true;
                    second_last_block_move_down = false
                  }
                }
                else
                {
                  last_block.m_block_lock = true;
                  second_last_block.m_block_lock = true;
                  last_block_move_down = false
                }
        
                if (last_block_move_down && second_last_block_move_down)
                {
                  this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                  last_block.m_pos.y -= 50;
                  last_block.m_pos_on_grid.y += 1;
                  last_block.m_virus.position.y -= 50;
        
        
                  this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                  this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                  second_last_block.m_pos.y -= 50;
                  second_last_block.m_pos_on_grid.y += 1;
                  second_last_block.m_virus.position.y -= 50;
                }
    
                // On déplace et ajuste le bro_link en meme temps
                this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x + 50
                this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y
    
              }
              break
              case '180':
                {
                  // console.log("this.actual_orientation === '180'")

                  //On traite la colision du dernier bloc
                  if (last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] === '.' && last_block.m_block_is_virus === false)
                  {     
                    this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                    this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                    last_block.m_pos.y -= 50;
                    last_block.m_pos_on_grid.y += 1;
                    last_block.m_virus.position.y -= 50;
                  }
                  else
                  {
                    last_block.m_block_lock = true;
                  }

                  //On traite la collision de l'avant dernier bloc
                  if (second_last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] === '.' && second_last_block.m_block_is_virus === false)
                  {     
                    this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                    this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                    second_last_block.m_pos.y -= 50;
                    second_last_block.m_pos_on_grid.y += 1;
                    second_last_block.m_virus.position.y -= 50;
                  }
                  else
                  {
                    second_last_block.m_block_lock = true;
                  }
                  // On déplace le bro_link en meme temps
                  this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x + 25 
                  this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y + 25
                }
                break
          case '270':
            {
              // console.log("this.actual_orientation === '90 ou 270'")
              let last_block_move_down = false
              let second_last_block_move_down = false
              //On traite la collision de l'avant dernier bloc
              if (last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] === '.' && last_block.m_block_is_virus === false && last_block.m_block_lock === false)
              {
                last_block_move_down = true
              //   //On traite la colision du dernier bloc
                if (second_last_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] === '.' && second_last_block.m_block_is_virus === false && second_last_block.m_block_lock === false)
                {     
                  second_last_block_move_down = true
                }  
                else
                {
                  last_block.m_block_lock = true;
                  second_last_block.m_block_lock = true;
                  second_last_block_move_down = false
                }
              }
              else
              {
                last_block.m_block_lock = true;
                second_last_block.m_block_lock = true;
                last_block_move_down = false
              }
      
              if (last_block_move_down && second_last_block_move_down)
              {
                this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] = this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x]
                this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                last_block.m_pos.y -= 50;
                last_block.m_pos_on_grid.y += 1;
                last_block.m_virus.position.y -= 50;
      
      
                this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] = this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x]
                this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                second_last_block.m_pos.y -= 50;
                second_last_block.m_pos_on_grid.y += 1;
                second_last_block.m_virus.position.y -= 50;
              }

              // On déplace et ajuste le bro_link en meme temps
              this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x
              this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y

            }
            break
            
      }
      }
      //----------END-------------MOUVEMENT BAS----------------

      //-----------------------Bouton HAUT rotation anti-horaire----------------
      if (p_y > 0) // Si on bouge vers la haut...
      {     
        // console.log(this.actual_orientation)

        switch(this.actual_orientation)
        {
          case '0' :

            if(this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] === '.' && 
            second_last_block.m_pos_on_grid.x >= 0  && 
            second_last_block.m_block_is_virus === false)
            {

              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x + 1] = 
              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x ]
              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
              second_last_block.m_virus.position.x += 50
              second_last_block.m_pos_on_grid.x += 1

              this.m_etat_table[last_block.m_pos_on_grid.y + 1][last_block.m_pos_on_grid.x] = 
              this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x ]
              this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
              last_block.m_virus.position.y -= 50
              last_block.m_pos_on_grid.y += 1
              // On déplace et ajuste le bro_link en meme temps
                  this.m_bro_links_array.at(-1).quaternion.set(0,0,1,0)
              this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x + 50
              this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y
              this.actual_orientation = '90'



            }

            break
          case '90' :
            if(this.m_etat_table[second_last_block.m_pos_on_grid.y - 1][second_last_block.m_pos_on_grid.x - 1] === '.' &&
            last_block.m_block_is_virus === false)
            {
              this.m_etat_table[second_last_block.m_pos_on_grid.y - 1][second_last_block.m_pos_on_grid.x - 1] = 
              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x ]
              this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
              second_last_block.m_virus.position.x -= 50
              second_last_block.m_virus.position.y += 50
              second_last_block.m_pos_on_grid.x -= 1
              second_last_block.m_pos_on_grid.y -= 1
              // On déplace et ajuste le bro_link en meme temps
                this.m_bro_links_array.at(-1).quaternion.set(0,0,0.707,-0.707)
              this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x + 25
              this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y + 25
              this.actual_orientation = '180'
            }
              break

            case '180' :
              if(this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] === '.' && 
              last_block.m_pos_on_grid.x + 1 < this.nb_col && 
              last_block.m_block_is_virus === false)
              {

                this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x + 1] = 
                this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x ]
                this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                last_block.m_virus.position.x += 50
                last_block.m_pos_on_grid.x += 1
                
                this.m_etat_table[second_last_block.m_pos_on_grid.y + 1][second_last_block.m_pos_on_grid.x] = 
                this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x ]
                this.m_etat_table[second_last_block.m_pos_on_grid.y][second_last_block.m_pos_on_grid.x] = '.'
                second_last_block.m_virus.position.y -= 50
                second_last_block.m_pos_on_grid.y += 1

                this.m_bro_links_array.at(-1).quaternion.set(0,0,0,-1)
                this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x 
                this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y 
                this.actual_orientation = '270'
              }
                break
                
            case '270' :
              if(
                last_block.m_pos_on_grid.y !== 0)
              {
                if(this.m_etat_table[last_block.m_pos_on_grid.y - 1][last_block.m_pos_on_grid.x - 1] === '.' && 
                last_block.m_block_is_virus === false)
                {
                  this.m_etat_table[last_block.m_pos_on_grid.y - 1][last_block.m_pos_on_grid.x - 1] = 
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x ]
                  this.m_etat_table[last_block.m_pos_on_grid.y][last_block.m_pos_on_grid.x] = '.'
                  last_block.m_virus.position.x -= 50
                  last_block.m_virus.position.y += 50
                  last_block.m_pos_on_grid.x -= 1
                  last_block.m_pos_on_grid.y -= 1
                  // On déplace et ajuste le bro_link en meme temps
                  this.m_bro_links_array.at(-1).quaternion.set(0,0,0.707,0.707)
                  this.m_bro_links_array.at(-1).position.x = this.m_blocs_array.at(-1).m_virus.position.x + this.bro_link_pos_adjustement_x + 25
                  this.m_bro_links_array.at(-1).position.y = this.m_blocs_array.at(-1).m_virus.position.y + this.bro_link_pos_adjustement_y - 25
                  this.actual_orientation = '0'
                }
              }
                break
        }


      //-----------END------------Bouton HAUT rotation anti-horaire----------------
      }
      // this.show_state_table();
    }
    free_falling(){

      this.m_all_block_down = true;
      let can_move = false
      can_move
      let bloc_have_bro = false
      let bro_link = null
      for (let bloc of this.m_blocs_array)
      {
        bloc_have_bro = false
        can_move = false
        
        //Si nous ne sommes pas au fond ou en colision avec un autre bloc en dessous : On bouge
        if (bloc.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] === '.' && bloc.m_block_is_virus === false)
        { 
          for (let bro_block of this.m_blocs_array)
          {
            if (bloc.m_brother_id === bro_block.m_bloc_id) // Avons nous un bro si oui...
            {
              bloc_have_bro = true

              for (let z = 0; z < this.m_bro_links_array.length; z++) //Trouvons le bro_link associé au bloc
              {
                if(this.m_bro_links_array[z].first_bro_id === bloc.m_bloc_id || this.m_bro_links_array[z].last_bro_id === bloc.m_bloc_id)
                {
                  bro_link = this.m_bro_links_array[z]
                }
              }
              
              if(bloc.m_bloc_id < bro_block.m_bloc_id)
              {
                if (bloc.m_pos_on_grid.y === bro_block.m_pos_on_grid.y ) //Si nous avons un bro horizontal
                {
                  if (bro_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[bro_block.m_pos_on_grid.y + 1][bro_block.m_pos_on_grid.x] === '.')
                  {
      
                    this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] = this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x]
                    this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x] = '.'
      
                    bloc.m_pos.y -= 50;
                    bloc.m_pos_on_grid.y += 1;
                    bloc.m_virus.position.y -= 50;
                    this.m_all_block_down = false
      
                    //On bouge le frere tout de suite
                    this.m_etat_table[bro_block.m_pos_on_grid.y + 1][bro_block.m_pos_on_grid.x] = this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x]
                    this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x] = '.'
            
                    bro_block.m_pos.y -= 50;
                    bro_block.m_pos_on_grid.y += 1;
                    bro_block.m_virus.position.y -= 50;
                    bro_link.position.y -= 50
      
                  }

                }

                else //Sinon nous avon un vertical avec bro
                {
                  if (bloc.m_pos_on_grid.y > bro_block.m_pos_on_grid.y) //sinon si le bloc est en bas on regarde le bloc
                  {
                    if (bloc.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] === '.')
                    {
                      this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] = this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x]
                      this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x] = '.'
        
                      bloc.m_pos.y -= 50;
                      bloc.m_pos_on_grid.y += 1;
                      bloc.m_virus.position.y -= 50;
                      this.m_all_block_down = false
        
                      //On bouge le frere tout de suite
                      this.m_etat_table[bro_block.m_pos_on_grid.y + 1][bro_block.m_pos_on_grid.x] = this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x]
                      this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x] = '.'
              
                      bro_block.m_pos.y -= 50;
                      bro_block.m_pos_on_grid.y += 1;
                      bro_block.m_virus.position.y -= 50;
                      bro_link.position.y -= 50
                    }

                  }

                  if (bloc.m_pos_on_grid.y < bro_block.m_pos_on_grid.y) //sinon si le bro_bloc est en bas on regarde le bro_bloc
                  {
                    if (bro_block.m_pos_on_grid.y < this.nb_lignes - 1 && this.m_etat_table[bro_block.m_pos_on_grid.y + 1][bro_block.m_pos_on_grid.x] === '.')
                    {
                      this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] = this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x]
                      this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x] = '.'
        
                      bloc.m_pos.y -= 50;
                      bloc.m_pos_on_grid.y += 1;
                      bloc.m_virus.position.y -= 50;
                      this.m_all_block_down = false
        
                      //On bouge le frere tout de suite
                      this.m_etat_table[bro_block.m_pos_on_grid.y + 1][bro_block.m_pos_on_grid.x] = this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x]
                      this.m_etat_table[bro_block.m_pos_on_grid.y][bro_block.m_pos_on_grid.x] = '.'
              
                      bro_block.m_pos.y -= 50;
                      bro_block.m_pos_on_grid.y += 1;
                      bro_block.m_virus.position.y -= 50;
                      bro_link.position.y -= 50
                    }
                  }
                }
              }
            }
          }
          if(!bloc_have_bro)
          {
            
            this.m_etat_table[bloc.m_pos_on_grid.y + 1][bloc.m_pos_on_grid.x] = this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x]
            this.m_etat_table[bloc.m_pos_on_grid.y][bloc.m_pos_on_grid.x] = '.'

            bloc.m_pos.y -= 50;
            bloc.m_pos_on_grid.y += 1;
            bloc.m_virus.position.y -= 50;
            this.m_all_block_down = false
          }
        }
      }

    }
    verify_all_grid_clear(){
      let indice_to_clear = [];
      let nb_blocs_lign = 0;
      let get_out = false;
      //Parcour du tableau d'état pour trouver les ligne horizontales de plus de 4 blocs
      for (let i = 0; i < this.nb_lignes; i++)
      {
          for (let y = 0; y < this.nb_col; y++)
          {
              if (this.m_etat_table[i][y] != '.')
              {
                  while (get_out === false && ((y + nb_blocs_lign) <= (this.nb_col - 1))) //6 doit etre valider.
                  {
                    if (this.m_etat_table[i][y] === this.m_etat_table[i][y + 1 + nb_blocs_lign])
                      {
                          nb_blocs_lign += 1;
                      }
                      else
                      {
                          get_out = true;
                          if(nb_blocs_lign >= 3)
                          {
                              for (let z = 0; z <= nb_blocs_lign; z++)
                              {
                                  indice_to_clear.push(new THREE.Vector2((y + z), i));
                              }
                              y = y + nb_blocs_lign; // ne pas vérifer les blocs déja tagués
                              this.m_all_block_down = false
                          }
                          nb_blocs_lign = 0;
                      }
                  }
                  get_out = false;
              }
          }
      }
      //Parcour du tableau d'état pour trouver les ligne verticales de plus de 4 blocs
      for (let i = 0; i < this.nb_lignes; i++)
      {
          for (let y = 0; y < this.nb_col; y++)
          {
              if (this.m_etat_table[i][y] != '.')
              {
                  while (get_out === false && ((i + nb_blocs_lign) <= (this.nb_lignes - 1)))
                  {
                    if((i + 1 + nb_blocs_lign) < this.nb_lignes)
                    {
                        if (this.m_etat_table[i][y] == this.m_etat_table[i + 1 + nb_blocs_lign][y])
                        {
                            nb_blocs_lign += 1;
                        }
                        else
                        {
                            get_out = true;
                            if(nb_blocs_lign >= 3)
                            {
                                for (let z = 0; z <= nb_blocs_lign; z++)
                                {
                                  indice_to_clear.push(new THREE.Vector2(y, (i + z)));
                                }
                                // i = i + nb_blocs_lign; // ne pas vérifer les blocs déja tagués

                              this.m_all_block_down = false
                            }
                            nb_blocs_lign = 0;
                        }
                    }
                    else{
                      get_out = true;
                      if(nb_blocs_lign >= 3)
                      {
                          for (let z = 0; z <= nb_blocs_lign; z++)
                          {
                            indice_to_clear.push(new THREE.Vector2(y, (i + z)));
                          }
                          // i = i + nb_blocs_lign; // ne pas vérifer les blocs déja tagués
                      }
                      nb_blocs_lign = 0;
                    }

                  }
                  get_out = false;
              }
          }
      }
      for (let i = 0; i < indice_to_clear.length; i++) // Parcour de tous les indices à supprimer dans le tableau d'état et dans le vector d'objet 
      {
          this.set_block_from_pos_in_table(indice_to_clear[i].x, indice_to_clear[i].y, '.'); //On met le tableau d'état a jour
          for (let y = 0; y < this.m_blocs_array.length; y++)
          {
              if (this.m_blocs_array[y].m_pos_on_grid.x === indice_to_clear[i].x && this.m_blocs_array[y].m_pos_on_grid.y == indice_to_clear[i].y)
              {
                for (let z = 0; z < this.m_bro_links_array.length; z++)
                {
                  if(this.m_bro_links_array[z].first_bro_id === this.m_blocs_array[y].m_bloc_id || this.m_bro_links_array[z].last_bro_id === this.m_blocs_array[y].m_bloc_id)
                  {

                  this.m_scene.remove(this.m_bro_links_array[z])
                  this.m_bro_links_array.splice(z, 1);
                    // console.log("TO REMOVE : " + link.first_bro_id)
                    // console.log(bro.last_bro_id)

                  }
                }
                if (this.m_blocs_array[y].m_block_is_virus)
                {
                  this.m_nb_virus_left --
                  this.show_virus_left()
                  this.sounds[3].play()
                }
                this.m_blocs_array[y].destroy(() =>{
                  this.sounds[0].play()

                  if(this.m_nb_virus_left === 0)
                  {
                    this.goto_next_level()
                    this.sounds[2].play()
                  }
                  
                })
                this.m_blocs_array.splice(y, 1);

              }
          }
      }
    }
    
    //------------------------------------------------------------------------------------------------------------------------------------------------
    // ********** **   ******  **   **                 ******** **     ** ****     **   ******  ********** **   *******   ****     **
    // /////**/// /**  **////**/**  **                 /**///// /**    /**/**/**   /**  **////**/////**/// /**  **/////** /**/**   /**
    //     /**    /** **    // /** **                  /**      /**    /**/**//**  /** **    //     /**    /** **     //**/**//**  /**
    //     /**    /**/**       /****                   /******* /**    /**/** //** /**/**           /**    /**/**      /**/** //** /**
    //     /**    /**/**       /**/**                  /**////  /**    /**/**  //**/**/**           /**    /**/**      /**/**  //**/**
    //     /**    /**//**    **/**//**                 /**      /**    /**/**   //****//**    **    /**    /**//**     ** /**   //****
    //     /**    /** //****** /** //**                /**      //******* /**    //*** //******     /**    /** //*******  /**    //***
    //     //     //   //////  //   //                 //        ///////  //      ///   //////      //     //   ///////   //      /// 
    //------------------------------------------------------------------------------------------------------------------------------------------------

    handle_tick(){
      //tick function-------------------------------TIC-----------------------TAC
      let last = 0; // timestamp of the last render() call
      const tick = () =>
      {
          if (this.game_over){
            return;
          }

          const elapsedTime = this.clock.getElapsedTime();
          // Si le tableau de blocs existe
          if(this.m_blocs_array !== null)
              {   

                  this.m_all_block_lock = true;
                  for (let i = 0; i < this.m_blocs_array.length; i++)
                  {
                      this.m_blocs_array[i].update_position()
                      if (this.m_blocs_array[i].m_block_lock === false)
                      {
                          this.m_all_block_lock = false;
                      }
                      if(this.m_blocs_array[i].m_block_is_virus)
                      {
                        this.m_blocs_array[i].rotY(Math.cos(elapsedTime) * this.m_blocs_array[i].speed_rotation);
                        this.m_blocs_array[i].m_virus.position.x += Math.cos(elapsedTime * elapsedTime / 2 + this.m_blocs_array[i].speed_rotation * 1000) / 40  
                        this.m_blocs_array[i].m_virus.position.y += Math.cos(elapsedTime * elapsedTime / 2 + this.m_blocs_array[i].speed_rotation * 1000) / 40
                        this.m_blocs_array[i].m_virus.position.z += Math.sin(elapsedTime * elapsedTime / 2 + this.m_blocs_array[i].speed_rotation * 1000) / 15
                      }
                      else
                      {
                        this.m_blocs_array[i].rotY(this.m_blocs_array[i].speed_rotation);
                      }
                  }
                  if (this.m_all_block_lock)
                  {
                        this.control_to_user = false
                        this.move_down = false
                        this.verify_all_grid_clear();
                  }
                  // on descends le dernier blocs à tous les ..
                  if(!last || elapsedTime - last >= 0.3 * this.levels[this.actual_level].speed) {
                      last = elapsedTime;

                      if(this.m_all_block_down)
                      {
                        if (this.m_all_block_lock)
                        {
                          this.add_bloc();
                          this.control_to_user = true
                        }
                        else
                        {
                          if(!this.move_down)
                          {
                            this.move_obj(0, -50, 0);
                          }
                        }
                      }
                      else
                      {
                        this.m_all_block_down = true;//force car un petit bug pafois ?
                        this.free_falling()
                      }
                  }
              }
              //animation du bro_link
              if(this.m_bro_links_array !== null)
              {
                for(let bro_link of this.m_bro_links_array)
                {
                  bro_link.rotateX(-0.04)
                }
              }
                //MOVING GAMEPAD 1
                this.checkGamepadsButtons();
                if (this.GPmove_down || this.GPmove_down_btn)
                {
                  if(!this.move_down_ellapse_last || this.clock.getElapsedTime() - this.move_down_ellapse_last >= 0.04) {
                    this.move_down_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(0, -50, 0);
                  }
                }
                if (this.GPmove_up)
                {
                  if(!this.move_up_ellapse_last || this.clock.getElapsedTime() - this.move_up_ellapse_last >= 0.2) {
                    this.move_up_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(0, 50, 0);
                  }
                }
                if (this.GPmove_left)
                {
                  if(!this.move_left_ellapse_last || this.clock.getElapsedTime() - this.move_left_ellapse_last >= 0.15) {
                    this.move_left_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(50, 0, 0);
                  }
                }
                if (this.GPmove_right)
                {
                  if(!this.move_right_ellapse_last || this.clock.getElapsedTime() - this.move_right_ellapse_last >= 0.15) {
                    this.move_right_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(-50, 0, 0);
                  }
                }
                //MOVING KEYBOARD
                if (this.move_down)
                {
                  if(!this.move_down_ellapse_last || this.clock.getElapsedTime() - this.move_down_ellapse_last >= 0.04) {
                    this.move_down_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(0, -50, 0);
                  }
                }
                if (this.move_up)
                {
                  if(!this.move_up_ellapse_last || this.clock.getElapsedTime() - this.move_up_ellapse_last >= 0.2) {
                    this.move_up_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(0, 50, 0);
                  }
                }
                if (this.move_left)
                {
                  if(!this.move_left_ellapse_last || this.clock.getElapsedTime() - this.move_left_ellapse_last >= 0.15) {
                    this.move_left_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(50, 0, 0);
                  }
                }
                if (this.move_right)
                {
                  if(!this.move_right_ellapse_last || this.clock.getElapsedTime() - this.move_right_ellapse_last >= 0.15) {
                    this.move_right_ellapse_last = this.clock.getElapsedTime();
                    this.move_obj(-50, 0, 0);
                  }
                }
          this.renderer.render(this.m_scene, this.camera);

          
      }
      this.renderer.setAnimationLoop(tick)


    }
 }