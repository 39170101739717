import { Std_blocs } from "../../bisk/std_blocs";
import { Virus_blocs } from "../../bisk/virus_blocs";

export class Facto_blocs {
    m_bloc = null;

    set_bloc_type = p_type => {
        switch (p_type) {

            case 'virus':
                this.m_bloc = Virus_blocs;
            break;

            case 'std':
                this.m_bloc = Std_blocs;
            break;

            default:
            break;

        }
    };

    
    create_bloc = (p_type, p_scene, p_mtl_loader, p_obj_loader, p_block_depart, p_model_virus_array, p_nb_lignes, p_nb_cols, p_id, p_nb_color) => {
        // console.log("create_blocs : " + p_id)
        this.set_bloc_type(p_type);
        switch (p_type) {
        case 'virus':
            return new this.m_bloc(p_scene, p_mtl_loader, p_obj_loader, p_block_depart, p_model_virus_array, p_nb_lignes, p_nb_cols, p_id, p_nb_color);
        case 'std':
            return new this.m_bloc(p_scene, p_mtl_loader, p_obj_loader, p_block_depart, p_model_virus_array, p_id, p_nb_color);
        }
        };
    }