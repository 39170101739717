<template>
  <div class="relative z-30 object-fill">
      <img class="align_arcade object-fill h-screen" src="/img/arcadebg.png"  alt="ArcadeBox">
  </div>
</template>

<script>

export default {
  components: {
  },
  data (){
    return{
    }
  },
  
}
</script>
<style scoped>
.align_arcade {
  z-index: 500;
}
</style>