import { ShipPU } from "../../bisk/shipPU";

export class Facto_PU {
    m_power_up = null;

    set_PU_type = p_type => {
        switch (p_type) {

            case 'vaisseau':
                this.m_power_up = ShipPU;
            break;

            default:
            break;

        }
    };

    
    create_PU = (p_type, p_model, p_bullet_model , p_scene, p_bloc_array, p_etat_table, m_blocs_array) => {
        // console.log("create_blocs : " + p_id)
        this.set_PU_type(p_type);
        switch (p_type) {
        case 'vaisseau':
            return new this.m_power_up(p_model, p_bullet_model, p_scene, p_bloc_array, p_etat_table, m_blocs_array);
        }
        };
    }