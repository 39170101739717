<template>
<div class="game absolute top-0" id="gamediv">
    <canvas class="webgl"></canvas>
    <div class="absolute top-10" id="container">
      <div  id="game_options" >
        <div class="flex justify-center m-5">options :</div>
        <div class="flex relative z-40 justify-center m-5">
          <h1 class="p-2">Select Level : </h1>
          
          <select id="select_level" class="px-2 border-4 border-l-0 border-r-0 border-yellow-500 focus:outline-none  bg-black text-yellow-500 rounded-lg" v-model="m_level">
            
            <option v-for="choice in m_choices" :value="choice.value" :key="choice.name">{{choice.name}}</option>
          </select>
        </div>
        
      </div>
     

        <div class="startGame" id="startgame">
            <img class="relative z-50 startimg" id="startimg" src="/img/startgame.png" alt="startgame">
        </div>

        <div id="loading-screen">
            <div id="loader"></div>
            <!-- <div class="loaded"  id="loaded"></div> -->
            <div class="stage">
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
              </div>
        </div>
    </div>
</div>
</template>

<script>
import { Facto_games } from '../factories/facto_games';

export default {
  name: 'Game',
  props: {
    
  },
  mounted(){
    this.startGame();
    this.init_select_level()
  },
  data(){
    return{
      m_level: "",
      m_choices: [
        { name: "How good are you ?", value:"0" },
        { name: "Bofff", value:"1" },
        { name: "Not so bad", value:"2"  },
        { name: "Got It ?", value:"3"  },
        { name: "OK let's do this !", value:"4"  }
      ]
    }
  },
  methods: {

    
init_select_level(){
  this.m_level = 0

},

startGame(){
console.log("starting Game !")


const game_factory = new Facto_games();
let game = null;

let startgame_btn = document.getElementById('startimg')

//-------------------------Functions/listenner-------------------


startgame_btn.onmousedown = () => { 

  
    if(document.getElementById("game"))
    {
        document.getElementById("game").remove()
        game.get_rid_of_that_game();
    }
    game = game_factory.create_game('dr_mario', this.m_level);

    if(document.getElementById('gameover'))
    {
        document.getElementById('gameover').remove();
    }
    document.getElementById("game_options").remove()

};




    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.startGame{
    display: flex;
    justify-content: center;
}
.game{
    margin-top: 7%;
    margin-left: 11%;
    display: flex;
    justify-content: center;
    width: 63%;
    height: 73%;
}
  @media only screen and (max-width: 1700px) {
    .game {
      margin-top: 8%;
      margin-left: 13%;
      width: 73%;
      height: 74%;
    }
  }
  @media only screen and (max-width: 1700px) {
    .game {
      margin-top: 8%;
      margin-left: 13%;
      width: 77%;
      height: 74%;
    }
  }
  @media only screen and (max-width: 1030px) {
    .game {
      margin-top: 20%;
    }
  }
  @media only screen and (max-width: 800px) {
    .game {
      margin-top: 20%;
    }
  }
  @media only screen and (max-width: 600px) {
    .game {
      height: 80%;
      margin-top: 25%;
    }
  }

.startimg{
    width: 15%;
}

.gameOver{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    top: 30%;
    z-index: 99;

}

.gameoverimg{
    width: 60%;
}

.webgl {
    display: flex;
    position: relative;
}

.loaded {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 110;
    margin-top: 30%;
    font-size: 40px;
    color: #BA55D3;
    text-shadow: -15px 5px 20px #961084;
    letter-spacing: -0.05em;
    opacity: 1;
    transition: 1s opacity;
}

#loading-screen {
    display: none;
    position: absolute;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 110;
    margin-top: 10%;
    background-color: #000000;
    opacity: 1;
    transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 5s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.stage {
  height: 300px;
  width: 500px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  perspective: 9999px;
  transform-style: preserve-3d;
}

.layer {
    display: flex;
    position: absolute;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 110;
    margin-top: 25%;
    font-size: 40px;
    color: #BA55D3;
    left: 80px;
    transform-style: preserve-3d;
    animation: ಠ_ಠ 5s infinite alternate ease-in-out -7.5s;
    animation-fill-mode: forwards;
    transform: rotateY(40deg) rotateX(33deg) translateZ(0);
    z-index: 110;
}



.layer:after {
  font: Helvetica, sans-serif;
  content: attr(data-after);
  white-space: pre;
  position: absolute;
  color: rgb(206, 32, 191);
  letter-spacing: -2px;
  text-shadow: 4px 0 10px rgba(0, 0, 0, 0.13);
}

.layer:nth-child(1):after {
  transform: translateZ(0px);
}

.layer:nth-child(2):after {
  transform: translateZ(-1.5px);
}

.layer:nth-child(3):after {
  transform: translateZ(-3px);
}

.layer:nth-child(4):after {
  transform: translateZ(-4.5px);
}

.layer:nth-child(5):after {
  transform: translateZ(-6px);
}

.layer:nth-child(6):after {
  transform: translateZ(-7.5px);
}

.layer:nth-child(7):after {
  transform: translateZ(-9px);
}

.layer:nth-child(8):after {
  transform: translateZ(-10.5px);
}

.layer:nth-child(9):after {
  transform: translateZ(-12px);
}

.layer:nth-child(10):after {
  transform: translateZ(-13.5px);
}

.layer:nth-child(11):after {
  transform: translateZ(-15px);
}

.layer:nth-child(12):after {
  transform: translateZ(-16.5px);
}

.layer:nth-child(13):after {
  transform: translateZ(-18px);
}

.layer:nth-child(14):after {
  transform: translateZ(-19.5px);
}

.layer:nth-child(15):after {
  transform: translateZ(-21px);
}

.layer:nth-child(16):after {
  transform: translateZ(-22.5px);
}

.layer:nth-child(17):after {
  transform: translateZ(-24px);
}

.layer:nth-child(18):after {
  transform: translateZ(-25.5px);
}

.layer:nth-child(19):after {
  transform: translateZ(-27px);
}

.layer:nth-child(20):after {
  transform: translateZ(-28.5px);
}

.layer:nth-child(n+10):after {
  -webkit-text-stroke: 3px rgba(0, 0, 0, 0.25);
}

.layer:nth-child(n+11):after {
  -webkit-text-stroke: 15px dodgerblue;
  text-shadow: 6px 0 6px #00366b, 5px 5px 5px #002951, 0 6px 6px #00366b;
}

.layer:nth-child(n+12):after {
  -webkit-text-stroke: 15px #0077ea;
}

.layer:last-child:after {
  -webkit-text-stroke: 17px rgba(0, 0, 0, 0.1);
}

.layer:first-child:after {
  color: #fff;
  text-shadow: none;
}

@keyframes ಠ_ಠ {
  100% {
    transform: rotateY(-40deg) rotateX(-43deg);
  }
}


</style>
