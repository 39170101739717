
import gsap from 'gsap'
import * as THREE from 'three';
export class ShipPU {

    constructor(p_model, p_bullet_model, p_scene, p_bloc_array, p_etat_table, p_blocs_array)  {

      this.m_model = p_model;
      this.m_etat_table = p_etat_table
      this.m_bullet_model = p_bullet_model.clone()
      this.m_bloc_array = p_blocs_array

      this.m_bullet_model.scale.set(0.07,0.25,0.07)
      this.m_bullet_model.position.x = this.m_model.position.x - 100 -200
      this.m_bullet_model.position.z = -800

      this.m_bullet_array = []
      this.m_bloc_array = p_bloc_array
      this.m_scene = p_scene

      this.m_model.scale.setScalar(7);
      this.m_model.position.x = -200 - 200
      this.m_model.position.y = -300
      this.m_model.position.z = -800

      this.move_down = false
      this.move_up =  false
      this.shooting = false

      this.m_scene.add(this.m_model)

      document.addEventListener('keydown', (key) => {
        if (key.key === 't')
        {
          this.move_up = true
          gsap.to(this.m_model.quaternion, { duration: 0.1, delay: 0, x: -0.3, y: 0 , z: 0, w: 1})
        }
        if (key.key === 'g')
        {
          this.move_down = true
          gsap.to(this.m_model.quaternion, { duration: 0.1, delay: 0, x: 0.3, y: 0 , z: 0, w: 1})
        }
        if (key.key === 'm')
        {
          this.shooting = true
        }
      })

      document.addEventListener('keyup', (key) => {
        if (key.key === 't')
        {
          this.move_up = false
          gsap.to(this.m_model.quaternion, { duration: 0.1, delay: 0, x: 0, y: 0 , z: 0, w: 0})
          // this.m_vaisseau.quaternion.set(0,0,0.707,0.707)
        }
        if (key.key === 'g')
        {

          this.move_down = false
          gsap.to(this.m_model.quaternion, { duration: 0.1, delay: 0, x: 0, y: 0 , z: 0, w: 0})
          // this.m_vaisseau.quaternion.set(0,0,0.707,0.707)
        }
        if (key.key === 'm')
        {
          this.shooting = false
        }

      })

      //BGSound1
      this.audio_listener = new THREE.AudioListener();
      this.m_scene.camera.add(this.audio_listener)
      this.audioLoader = new THREE.AudioLoader();
      this.index_to_fire = 0
      // create a global audio source

      this.shot_fired = []
      for(let i = 0; i < 4; i++)
      {
        this.shot_fired.push(new THREE.Audio( this.audio_listener ))
        // load a sound and set it as the Audio object's buffer
        this.audioLoader.load( 'music/bullet.mp3', (buffer) => {
          this.shot_fired[i].setBuffer( buffer );
          this.shot_fired[i].setLoop( false );
          this.shot_fired[i].setVolume( 0.4);
        });
      }




      this.handle_tick()
    }

    handle_tick(){
    // Animate
    const clock = new THREE.Clock()
    let last = 0;

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
      //VAISSEAU MOVE
      if (this.move_down)
      {
        this.m_model.position.y -= 20
      }
      if (this.move_up)
      {
        this.m_model.position.y += 20
      }
      if (this.shooting)
      {
        if(!last || elapsedTime - last >= 0.25) {
          last = elapsedTime;
          
          this.m_bullet_array.push(this.m_bullet_model.clone())
          this.m_bullet_array.at(-1).position.y = this.m_model.position.y
          this.m_scene.add(this.m_bullet_array.at(-1))

            this.shot_fired[this.index_to_fire].play();
            this.index_to_fire++
            if (this.index_to_fire > 3)
            {
              this.index_to_fire = 0
            }

          gsap.to(this.m_bullet_array.at(-1).position, { duration: 0.5, delay: 0, x: 900})
        }
      }
      
      let blocs_index = 0
      for (let blocs of this.m_bloc_array)
      {
        let bullets_index = 0
        for(let bullets of this.m_bullet_array)
        {
          if(blocs.m_virus.position.distanceTo(bullets.position) < 50)
          {
            blocs.destroy()
            this.m_etat_table[blocs.m_pos_on_grid.y][blocs.m_pos_on_grid.x] = "."

            this.m_scene.remove(bullets)
            this.m_bullet_array.splice(bullets_index, 1)
            this.m_bloc_array.splice(blocs_index, 1)
          }
          if(bullets.position.x > 800)
          {
            this.m_scene.remove(bullets)
            this.m_bullet_array.splice(bullets_index, 1)
          }
          bullets_index++

        }
        blocs_index++
      }

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }
    tick()
  }
}

